import axios from 'axios';

export async function handleAdminLogin(params) {
  const { data } = await axios.post(
    `${import.meta.env.VITE_BASE_SELLER_VENDOR_URL}/auth/admin-login`,
    params
  );

  return data;
}

export async function handleUserLogin(params) {
  const { data } = await axios.post(
    `${import.meta.env.VITE_BASE_SELLER_VENDOR_URL}/auth/partner-login`,
    params
  );

  return data;
}

export async function updateAccessToken(params) {
  const res = await axios.post(
    `${import.meta.env.VITE_BASE_SELLER_VENDOR_URL}/auth/refresh`,
    params
  );

  return res;
}
