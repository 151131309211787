import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { STORAGE_TEMPERATURE_TYPE_HASH, TAX_TYPE_HASH } from 'commons/hash';
import toastify from 'commons/toast';
import productMasterApi from 'service/hq/master';
import {
  addAllOption,
  handleError,
  isEmpty,
  translateObjectKeys,
  updateMyDataMaker,
} from 'commons/helper';
import { downloadJsonAsExcel } from 'commons/excel';
import useFetch from 'hooks/useFetch';
import useSelectOptions from 'hooks/useSelectOptions';

import { Flex } from 'styles/components';
import Card from 'components/Card';
import Select from 'components/Select';
import TextareaSearchbar from 'components/TextareaSearchbar';
import PaginationTable from 'components/Table/PaginationTable';
import ResultHeader from 'components/ResultHeader';
import Button from 'components/Button';
import { ROW_SELECT_PLUGIN_HOOKS } from 'components/Table/commons/constant';

import { useGlobalModals } from 'hooks/pages';
import {
  COLUMNS,
  MAPPING_KEYS,
  SEARCH_CONDITIONS,
  SEARCH_OPTIONS_INITIAL_VALUES,
} from './commons/constant';
import EditableCell from './components/EditableCell';
import { addDefaultValues, getUpdateItemList } from './commons/helper';

function AdminReceivingUploadPage() {
  const [searchOptions, setSearchOptions] = useState(SEARCH_OPTIONS_INITIAL_VALUES);
  const [controlledPage, setControlledPage] = useState(0);
  const [selectedProductIndexes, setSelectedProductIndexes] = useState([]);
  const autoResetSelectedRows = useRef();
  const searchOptionsSnapshot = useRef(SEARCH_OPTIONS_INITIAL_VALUES);
  const history = useHistory();
  const { itemTypeCode, stockProductStatus } = useSelectOptions([
    'itemTypeCode',
    'stockProductStatus',
  ]);
  const [, setModalOpen] = useGlobalModals();
  const {
    data: { content = [], totalElements = 0, totalPages = 0 } = {},
    execute: fetchProductMasterList,
    setState: setProductMasterList,
  } = useFetch(productMasterApi.fetchProductMasterList, {
    manipulateFunc: addDefaultValues,
    initialExecute: false,
  });

  useEffect(() => {
    autoResetSelectedRows.current = true;
  }, [content]);

  const tableOptions = {
    initialState: { pageIndex: 0, pageSize: 50 },
    manualPagination: true,
    pageCount: totalPages,
    useControlledState: (state) => ({
      ...state,
      pageIndex: controlledPage,
    }),
    autoResetSelectedRows: autoResetSelectedRows.current,
    defaultColumn: {
      Cell: EditableCell,
    },
    updateMyData: updateMyDataMaker(setProductMasterList, {
      properties: ['data', 'content'],
      autoResetSelectedRows,
    }),
  };

  const handleSelectChange = (e) => {
    setSearchOptions((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSearchQueryChange = (e) => {
    setSearchOptions((prev) => ({ ...prev, query: e.target.value }));
  };

  const handleCheckedValueChange = (e) => {
    setSearchOptions((prev) => ({ ...prev, searchCondition: e.target.value }));
  };

  const handleSearchClick = () => {
    searchOptionsSnapshot.current = searchOptions;
    setControlledPage(0);
    fetchProductMasterList({
      productStatus: searchOptions.stockProductStatus,
      productType: searchOptions.itemTypeCode,
      searchCondition: searchOptions.searchCondition,
      searchContent: searchOptions.query,
    });
  };

  const handleResetClick = () => {
    setSearchOptions(SEARCH_OPTIONS_INITIAL_VALUES);
    searchOptionsSnapshot.current = SEARCH_OPTIONS_INITIAL_VALUES;
    setControlledPage(0);
    fetchProductMasterList();
  };

  const handlePageChange = ({ state }) => {
    fetchProductMasterList({
      productStatus: searchOptionsSnapshot.current.stockProductStatus,
      productType: searchOptionsSnapshot.current.itemTypeCode,
      searchCondition: searchOptionsSnapshot.current.searchCondition,
      searchContent: searchOptionsSnapshot.current.query,
      pageIndex: state.pageIndex,
    });
  };

  const translateList = (list) => {
    return list.map((item) => translateObjectKeys(item, MAPPING_KEYS));
  };

  const handleExcelDownloadClick = async () => {
    if (isEmpty(content)) {
      toastify('검색결과가 존재하지 않습니다.', { variant: 'warn' });
      return;
    }

    setModalOpen('activityIndicator', true);

    try {
      const { response } = await productMasterApi.fetchProductMasterList({
        productStatus: searchOptionsSnapshot.current.stockProductStatus,
        productType: searchOptionsSnapshot.current.itemTypeCode,
        searchCondition: searchOptionsSnapshot.current.searchCondition,
        searchContent: searchOptionsSnapshot.current.query,
        pageSize: 100000,
      });
      downloadJsonAsExcel({
        data: translateList(
          response.data.content.map((item) => ({
            ...item,
            tax: TAX_TYPE_HASH[item.tax],
            storageTemperature: STORAGE_TEMPERATURE_TYPE_HASH[item.storageTemperature],
          }))
        ),
        sheetName: '품목 마스터 검색 결과',
        fileName: '품목 마스터 검색 결과',
      });
    } catch (error) {
      handleError(error);
    } finally {
      setModalOpen('activityIndicator', false);
    }
  };

  const handleExcelUploadClick = () => {
    history.push('/admin/product/upload');
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (isEmpty(selectedProductIndexes)) {
      toastify('저장할 품목이 존재하지 않습니다.', { variant: 'warn' });
      return;
    }

    const selectedProducts = content.filter((_, index) => selectedProductIndexes.includes(index));

    const validation임박전환일 = selectedProducts.map(
      ({ imminentStockConversionDays }) => imminentStockConversionDays
    );

    if (validation임박전환일.some((item) => Number(item) < 90)) {
      toastify('임박전환(일) 은 90일 이상으로 입력하셔야 합니다.', { variant: 'error' });
      return;
    }

    try {
      await productMasterApi.saveProductMasterList(getUpdateItemList(selectedProducts));
      toastify(`품목 마스터 ${selectedProductIndexes.length}건이 저장되었습니다.`, {
        variant: 'success',
      });
      fetchProductMasterList({
        productStatus: searchOptionsSnapshot.current.stockProductStatus,
        productType: searchOptionsSnapshot.current.itemTypeCode,
        searchCondition: searchOptionsSnapshot.current.searchCondition,
        searchContent: searchOptionsSnapshot.current.query,
        pageIndex: controlledPage,
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleProductSelect = ({ selectedFlatRows }) => {
    const productIndexes = selectedFlatRows.map(({ index }) => index);
    setSelectedProductIndexes(productIndexes);
  };

  const resultHeaderButtons = [
    <Button $variant='light' onClick={handleExcelDownloadClick}>
      엑셀 다운로드
    </Button>,
    <Button $variant='light' onClick={handleExcelUploadClick}>
      엑셀 업로드
    </Button>,
    <Button $variant='secondary' type='submit'>
      선택한 품목 저장
    </Button>,
  ];

  return (
    <Card title='품목 마스터 (재고상품)'>
      <Flex $flexDirection='column' $gap='var(--space-m)'>
        <Flex $gap='var(--space-m)'>
          <Select
            id='itemTypeCode'
            name='itemTypeCode'
            label='품목 유형'
            value={searchOptions.itemTypeCode}
            onChange={handleSelectChange}
            options={addAllOption(itemTypeCode)}
          />
          <Select
            id='stockProductStatus'
            name='stockProductStatus'
            label='품목 상태'
            value={searchOptions.stockProductStatus}
            onChange={handleSelectChange}
            options={addAllOption(stockProductStatus)}
          />
        </Flex>
        <TextareaSearchbar
          name='vendorSearchCondition'
          textareaValue={searchOptions.query}
          onTextareaChange={handleSearchQueryChange}
          radios={SEARCH_CONDITIONS}
          onSearchClick={handleSearchClick}
          onResetClick={handleResetClick}
          checkedValue={searchOptions.searchCondition}
          onCheckedValueChange={handleCheckedValueChange}
          placeholder='검색어를 입력해 주세요. 구분 문자 (쉼표, 엔터)로 복수 품목을 검색할 수 있습니다.'
        />
      </Flex>
      <form onSubmit={handleSave}>
        <ResultHeader totalCount={totalElements} buttons={resultHeaderButtons} />
        <PaginationTable
          columns={COLUMNS}
          data={content}
          onPageChange={handlePageChange}
          tableOptions={tableOptions}
          setControlledPage={setControlledPage}
          pluginHooks={ROW_SELECT_PLUGIN_HOOKS}
          onSelectChange={handleProductSelect}
        />
      </form>
    </Card>
  );
}

export default AdminReceivingUploadPage;
