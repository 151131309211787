import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { ReactComponent as Logo } from 'icons/svg/logo.svg';

import useFetch from 'hooks/useFetch';
import vendorMenuApi from 'service/menu';
import adminMenuApi from 'service/hq/menu';

import { getTokenData } from 'commons/token';
import { useAuthInfo } from 'hooks/useSyncAuth';
import { CONNECT_TO_SELLER_ADMIN_MENU_ID } from 'commons/constant';
import * as Styled from './styled';

const Sidebar = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const { storage } = useAuthInfo(['accessToken']);
  const user = getTokenData();

  const menuApi = !user?.is_vendor ? adminMenuApi : vendorMenuApi;

  const { execute, data: { adminMenuGroup1List = [] } = {} } = useFetch(menuApi.getMenuList, {
    initialExecute: false,
  });

  useEffect(() => {
    execute();
  }, [storage]);

  const renderMenuGroup3List = (menu) => {
    const {
      menuName,
      adminMenuGroup3Id,
      path,
      // has_read_permission, has_write_permission
    } = menu;
    const isConnectSellerAdminMenu = CONNECT_TO_SELLER_ADMIN_MENU_ID.some(
      (menuId) => menuId === menu.adminMenuGroup3Id
    );

    const onClickPush = () => {
      if (isConnectSellerAdminMenu) {
        const openNewWindow = window.open('about:blank');
        openNewWindow.location.href = `${import.meta.env.VITE_SELLER_ADMIN_URL}${path}`;
        return;
      }

      history.push(path);
    };

    return (
      <Styled.MenuItem key={`group-3-${adminMenuGroup3Id}`}>
        <Styled.Link selected={pathname.startsWith(path)} onClick={onClickPush}>
          {menuName}
        </Styled.Link>
      </Styled.MenuItem>
    );
  };

  const renderMenuGroup2List = (menu) => {
    const { menuName, adminMenuGroup2Id, adminMenuGroup3List } = menu;
    const key = `group-2-${adminMenuGroup2Id}`;
    // const navIcon = `nav-icon ${icon}`;

    return (
      <Styled.Group2List key={key}>
        <Styled.Group2ListLink>
          {/* <Styled.Group2Icon icon={navIcon} /> */}
          <span>{menuName}</span>
          {/* <Styled.RightArrow /> */}
        </Styled.Group2ListLink>
        <Styled.Group2TreeView>
          {adminMenuGroup3List.map((menu3) => {
            return renderMenuGroup3List(menu3);
          })}
        </Styled.Group2TreeView>
      </Styled.Group2List>
    );
  };

  const renderMenuGroup1List = () => {
    return adminMenuGroup1List.map((menu) => {
      const { menuName, adminMenuGroup1Id, adminMenuGroup2List } = menu;

      return (
        <Styled.Group1List key={`group-1-${adminMenuGroup1Id}`}>
          <Styled.Group1ListLink>
            <span>{menuName}</span>
          </Styled.Group1ListLink>
          {adminMenuGroup2List.map((menu2) => {
            return renderMenuGroup2List(menu2);
          })}
        </Styled.Group1List>
      );
    });
  };

  return (
    <Styled.Wrapper>
      <Styled.Header to='/'>
        <Logo />
      </Styled.Header>

      <Styled.Body>
        <Styled.Menu>
          <Styled.MenuList data-widget='treeview' role='menu' data-accordion='false'>
            {renderMenuGroup1List()}
          </Styled.MenuList>
        </Styled.Menu>
      </Styled.Body>
    </Styled.Wrapper>
  );
};

export default Sidebar;
